import React from "react"
import "./style.scss"
import DiamondIcon from "@mui/icons-material/Diamond"
import TimeLineIcon from "@mui/icons-material/Timeline"
import { Person, ImportantDevices, TimeLine } from "@mui/icons-material"

const AboutPage = () => {
  return (
    <>
      <div className="about-page" id="#about">
        <h1 className="about-title">Sobre</h1>
        <p className="about-subtitle">
          Nossas metodologia é simples, porém muito poderosa
        </p>
        <div className="about-items-wrapper">
          <div className="about-item">
            <div className="about-item-icon">
              <Person className="_1" />
            </div>
            <div className="about-item-text">
              <h3>Pessoas</h3>
              “As pessoas e o relacionamento humano é o que faz prosperar
              qualquer negócio, equipes e projetos.”  
            </div>
          </div>
          <div className="about-item">
            <div className="about-item-icon">
              <ImportantDevices className="_2" />
            </div>
            <div className="about-item-text">
              <h3>Melhoria Contínua</h3>
              Buscamos o aumento da eficiência operacional pela eliminação
              consistente e completa de processos que não agregam valor para o
              negócio e pela redução de desperdícios.
            </div>
          </div>
          <div className="about-item">
            <div className="about-item-icon">
              <TimeLineIcon className="_3" />
            </div>
            <div className="about-item-text">
              <h3>Agilidade</h3>
              Praticamos gestão de projetos ágil de produtos complexos e
              adaptativos e entregas fracionadas de valor no curto prazo.
            </div>
          </div>
          <div className="about-item">
            <div className="about-item-icon">
              <DiamondIcon className="_4" />
            </div>
            <div className="about-item-text">
              <h3>Gestão de Valor</h3>
              Priorizamos e qualificamos as oportunidades/demandas que compravam
              valor para o negócio, entendo o que faz sentido ser priorizado,
              eliminando desperdícios e redesenhando uma nova gestão com
              eficiência e agilidade.
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AboutPage
